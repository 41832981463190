import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { useClickAway, useKey, useWindowSize } from "react-use";
import Scrollbar from "react-scrollbars-custom";
import { ElementPropsWithElementRef } from "react-scrollbars-custom/dist/types/types";
import Draggable from "react-draggable";

import api from "../services/api";
import { refreshUser } from "../services/auth";
import { AppContext } from "./app-context";
import ArcText from "./arc-text";
import Definitions from "./definitions";
import Loader from "./loader";
import Syllables from "./syllables";
import Synonyms from "./synonyms";
import Pronunciation from "./pronunciation";

import {
  column,
  scrollbar,
  scrollWrapper,
  scroller,
  scrollThumb,
  wordHelper,
  wordHelper__modal,
  loader,
} from "./styles/word-helper.module.scss";

const ONE_COLUMN_BREAKPOINT = 800;

type Props = {
  className?: string;
  isModal?: boolean;
  onClose?: () => any;
  word?: string;
  path: string;
};

type WordData = {
  word: string;
  definitions: any[];
  syllables: string[];
  synonyms?: string[];
  hyphenated?: boolean;
};

const ColumnScroller = ({ children }: { children: any }) => {
  const { width } = useWindowSize();

  return (
    <Scrollbar
      disableTracksWidthCompensation
      native={width <= ONE_COLUMN_BREAKPOINT}
      className={column}
      trackYProps={{
        renderer: ({
          elementRef,
          ...rest
        }: PropsWithChildren<ElementPropsWithElementRef>) => {
          return <span {...rest} ref={elementRef} className={scrollbar} />;
        },
      }}
      wrapperProps={{
        renderer: ({
          elementRef,
          ...rest
        }: PropsWithChildren<ElementPropsWithElementRef>) => {
          return <div {...rest} ref={elementRef} className={scrollWrapper} />;
        },
      }}
      scrollerProps={{
        renderer: ({
          elementRef,
          ...rest
        }: PropsWithChildren<ElementPropsWithElementRef>) => {
          return <div {...rest} ref={elementRef} className={scroller} />;
        },
      }}
      thumbYProps={{
        renderer: ({
          elementRef,
          ...rest
        }: PropsWithChildren<ElementPropsWithElementRef>) => {
          return <div {...rest} ref={elementRef} className={scrollThumb} />;
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};

const getModalBody = ({
  isLoading,
  isModal,
  hasError,
  wordData,
}: {
  isLoading: boolean;
  isModal: boolean;
  hasError: boolean;
  wordData?: WordData;
}) => {
  if (isLoading) {
    return <Loader className={loader} />;
  }

  if (hasError) {
    return (
      <div className={loader}>
        <div>Oops! We don&apos;t know that one.</div>
      </div>
    );
  }

  if (wordData?.word) {
    const { definitions, syllables, synonyms } = wordData ?? {};

    return (
      <>
        <ColumnScroller>
          {syllables && (
            <>
              <Syllables syllables={syllables} />
              <Pronunciation word={wordData.word} />
            </>
          )}
          <Synonyms synonyms={synonyms} />
        </ColumnScroller>
        <ColumnScroller>
          {definitions && definitions.length > 0 ? (
            <Definitions definitions={definitions} />
          ) : (
            <ArcText width={300}>
              Sorry, we don&apos;t have a definition for this word
            </ArcText>
          )}
        </ColumnScroller>
      </>
    );
  }

  return (
    <div className={loader}>
      {isModal
        ? "Select a word and hit the button again to get help"
        : "Search for a word to get help"}
    </div>
  );
};

const WordHelper = ({
  className,
  isModal = false,
  onClose,
  word,
  path,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useContext(AppContext);
  const [wordData, setWordData] = useState<WordData>();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useClickAway(ref, () => onClose?.());

  useKey("Escape", (event) => {
    event.stopPropagation();
    onClose?.();
  });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      if (!word) {
        return;
      }

      setHasError(false);
      setIsLoading(true);
      try {
        const wordData: WordData = await api(user)("dictionary", {
          signal,
          searchParams: {
            word: decodeURIComponent(word)
              .replace(/[^\w\s]|_/g, "")
              .replace(/\s+/g, " "),
          },
        }).json();
        setWordData(wordData);
      } catch (error: unknown) {
        setHasError(true);
        if ((error as any).response?.status === 401) {
          void refreshUser();
        }
      }

      setIsLoading(false);
    };

    if (word && word !== wordData?.word) {
      void fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [user, word, wordData?.word]);

  return (
    <Draggable
      disabled={!isModal}
      onStart={(event) => {
        if (event.type === "touchstart") {
          return false;
        }
      }}
    >
      <div
        ref={ref}
        className={clsx(wordHelper, className, {
          [wordHelper__modal]: isModal,
        })}
      >
        {getModalBody({ isLoading, isModal, hasError, wordData })}
      </div>
    </Draggable>
  );
};

export default WordHelper;
