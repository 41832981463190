import React, {useRef, useState} from 'react';
import {useClickAway, useKey} from 'react-use';

import {
  field,
  fieldToggleButton
} from './styles/field-toggle-button.module.scss';

type Props = {
  icon: React.ReactNode;
  className: string;
  label: string;
  children: React.ReactNode;
};

const FieldToggleButton = ({icon, className, label, children}: Props) => {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setShow(false);
  });

  useKey('Escape', () => {
    setShow(false);
  });

  const toggle = () => {
    setShow(!show);
  };

  return (
    <div ref={ref} className={fieldToggleButton}>
      <button
        className={className}
        type="button"
        aria-label={label}
        aria-haspopup="menu"
        onClick={toggle}
      >
        {icon}
      </button>
      {show && (
        <div role="menu" className={field}>
          {children}
        </div>
      )}
    </div>
  );
};

export default FieldToggleButton;
