import React from 'react';

import Paragraph from './paragraph';

import {
  definitions as definitions_style,
  definition as definition_style
} from './styles/definitions.module.scss';

type Definition = {
  definition: string;
  partOfSpeech?: string;
};

type Props = {
  definitions?: Definition[];
};

const Definitions = ({definitions}: Props) => (
  <div className={definitions_style}>
    {definitions?.map(definition => (
      <Paragraph key={definition.definition} className={definition_style}>
        {`${
          definition.partOfSpeech ? `**${definition.partOfSpeech}** - ` : ''
        }${definition.definition}`}
      </Paragraph>
    ))}
  </div>
);

export default Definitions;
