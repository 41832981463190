import React, {useContext, useRef} from 'react';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {AppContext} from './app-context';

import ArcText from './arc-text';

type Props = {
  children: string;
  className?: string;
  fontSize?: number;
};

const Paragraph = ({children, className, fontSize, ...props}: Props) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const {width} = useMeasureDirty(ref);
  const {userOptions} = useContext(AppContext);

  const paragraphWidth = Math.min(
    width === 0 ? 950 : width - 50,
    userOptions?.width ?? 950
  );

  return (
    <p ref={ref} className={className} {...props}>
      <ArcText width={paragraphWidth} options={{fontSize}}>
        {children}
      </ArcText>
    </p>
  );
};

export default Paragraph;
