import React, {useEffect, useRef} from 'react';
import debounce, {DebounceSettings} from 'lodash-es/debounce';

import {inputRange} from './styles/input-range.module.scss';

type Props = {
  defaultValue?: number;
  isFocused?: boolean;
  label: string;
  minValue: number;
  maxValue: number;
  onChange: (event: any) => any;
  id: string;
};

const InputRange = ({
  defaultValue,
  isFocused,
  label,
  minValue,
  maxValue,
  onChange,
  id
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const {current} = inputRef;
    if (current && isFocused) {
      current.focus({preventScroll: true});
    }
  });

  const debounceEvent = (
    ...args: [(event: any) => any, number, DebounceSettings?]
  ) => {
    const debounced = debounce(...args);
    return (event: any) => {
      event.persist();
      return debounced(event);
    };
  };

  return (
    <div className={inputRange}>
      <label htmlFor={id}>
        {label}
        <input
          ref={inputRef}
          defaultValue={defaultValue}
          id={id}
          max={maxValue}
          min={minValue}
          type="range"
          onChange={debounceEvent(onChange, 200)}
        />
      </label>
    </div>
  );
};

export default InputRange;
