import React from 'react';

import {path} from './styles/loader.module.scss';

type Props = {
  className?: string;
};

const Loader = ({className}: Props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle fill="none" cx="50" cy="50" r="48" className={path}/>
  </svg>
);

export default Loader;
