import React, { ChangeEvent, forwardRef, useContext, useState } from "react";
import { useWindowSize } from "react-use";
import Tooltip from "@reach/tooltip";
import clsx from "clsx";
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button";

import { AppContext } from "./app-context";
import FieldToggleButton from "./field-toggle-button";
import InputRange from "./input-range";
import WordHelper from "./word-helper";
import ArcHeight from "../content/icons/arcHeight.svg";
import FontSize from "../content/icons/fontSize.svg";
import LetterSpacing from "../content/icons/letterSpacing.svg";
import Print from "../content/icons/print.svg";
import Width from "../content/icons/width.svg";
import WordHelperIcon from "../content/icons/wordHelper.svg";

import "@reach/tooltip/styles.css";
import "@reach/menu-button/styles.css";
import {
  tooltip,
  readerToolbar,
  button,
  menu,
  menuButton,
} from "./styles/toolbar.module.scss";

const MIN_WIDTH = 450;
const PADDING = 50;
const STATIC_FIELDS = {
  fontSize: {
    icon: <FontSize />,
    id: "textSize",
    label: "Text size",
    minValue: 6,
    maxValue: 50,
  },
  arcHeight: {
    icon: <ArcHeight />,
    id: "arcHeight",
    label: "Arc height",
    minValue: 1,
    maxValue: 10,
  },
  letterSpacing: {
    icon: <LetterSpacing />,
    id: "letterSpacing",
    label: "Letter spacing",
    minValue: 0,
    maxValue: 100,
  },
};
type ToolbarTipProps = {
  label: string;
  children: React.ReactNode[] | React.ReactNode;
};

const ToolbarTip = forwardRef<any, ToolbarTipProps>(
  ({ label, children, ...props }: ToolbarTipProps, ref) => (
    <Tooltip label={label} className={tooltip}>
      <div ref={ref} {...props}>
        {children}
      </div>
    </Tooltip>
  )
);

type ToolbarProps = {
  className?: string;
  onPrint?: () => any;
  isPrintLoading?: boolean;
  hasNoWidth?: boolean;
  maxWidth?: number;
  hasWordHelperCallback?: boolean;
};

const Toolbar = ({
  className,
  onPrint,
  isPrintLoading,
  hasNoWidth,
  maxWidth,
}: ToolbarProps) => {
  const { userOptions, setUserOptions } = useContext(AppContext);
  const [wordHelperOpen, setWordHelperOpen] = useState(false);
  const [word, setWord] = useState("");
  const { width } = useWindowSize();
  const updateOption =
    (option: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setUserOptions?.({
        [option]: Number.parseInt(event.target.value, 10),
      });
    };

  const openWordHelper = async () => {
    const selection = window.getSelection();

    if (!selection) {
      return;
    }

    const query = selection.toString().trim().split(" ")[0];
    selection.empty();
    setWord(query);

    setWordHelperOpen(true);
  };

  const closeWordHelper = () => {
    setWordHelperOpen(false);
  };

  const resetSettings = () => {
    setUserOptions?.();
  };

  const print = () => {
    if (onPrint) {
      onPrint();
    } else if (window) {
      try {
        const printed = document.execCommand("print", false);
        if (!printed) {
          window.print();
        }
      } catch {
        window.print();
      }
    }
  };

  const maxWidthWithPadding = Math.min(width, maxWidth ?? width) - PADDING;

  const fields = {
    ...STATIC_FIELDS,
  };
  if (!hasNoWidth && maxWidthWithPadding > MIN_WIDTH) {
    (fields as any).width = {
      icon: <Width />,
      label: "Line length",
      minValue: MIN_WIDTH,
      maxValue: maxWidthWithPadding,
    };
  }

  return (
    <div className={clsx(readerToolbar, className)}>
      {Object.keys(fields).map((field) => {
        const { icon, label, minValue, maxValue, id } = (fields as any)[field];
        return (
          <ToolbarTip key={field} label={label}>
            <FieldToggleButton className={button} icon={icon} label={label}>
              <InputRange
                isFocused
                defaultValue={userOptions?.[field]}
                label={label}
                maxValue={maxValue}
                minValue={minValue}
                id={id}
                onChange={updateOption(field)}
              />
            </FieldToggleButton>
          </ToolbarTip>
        );
      })}
      <button
        className={button}
        type="button"
        aria-label="WordHelper"
        onClick={openWordHelper}
      >
        <ToolbarTip label="WordHelper">
          <WordHelperIcon />
        </ToolbarTip>
      </button>
      <ToolbarTip label="Print">
        <button
          className={button}
          type="button"
          aria-label="Print"
          disabled={isPrintLoading}
          onClick={print}
        >
          {isPrintLoading ? "◌" : <Print />}
        </button>
      </ToolbarTip>
      <Menu>
        <MenuButton className={clsx(menuButton, button)}>⋮</MenuButton>
        <MenuList className={menu}>
          <MenuLink onSelect={resetSettings}>Reset settings</MenuLink>
        </MenuList>
      </Menu>
      {wordHelperOpen && (
        <WordHelper isModal word={word} onClose={closeWordHelper} />
      )}
    </div>
  );
};

export default Toolbar;
