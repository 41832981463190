import {useEffect} from 'react';

const WORD = /^\w*$/;

const selectWordOnClick = () => {
  const selection = window.getSelection();

  if (!selection || selection.rangeCount < 1) {
    return;
  }

  const range = selection.getRangeAt(0);
  const {anchorNode} = selection;

  if (!anchorNode?.nodeValue) {
    return;
  }

  const {length} = anchorNode.nodeValue;

  while (WORD.test(range.toString()) && range.startOffset > 0) {
    range.setStart(anchorNode, range.startOffset - 1);
  }

  if (!WORD.test(range.toString())) {
    range.setStart(anchorNode, range.startOffset + 1);
  }

  while (WORD.test(range.toString()) && range.endOffset < length) {
    range.setEnd(anchorNode, range.endOffset + 1);
  }

  if (!WORD.test(range.toString())) {
    range.setEnd(anchorNode, range.endOffset - 1);
  }

  selection.removeAllRanges();
  selection.addRange(range);
};

const useSelectWordOnClick = (ref: React.RefObject<Element>) => {
  useEffect(() => {
    const {current} = ref;

    current?.addEventListener('click', selectWordOnClick);

    return () => {
      current?.removeEventListener('click', selectWordOnClick);
    };
  });
};

export default useSelectWordOnClick;
