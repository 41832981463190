import React, {useRef, useState} from 'react';
import clsx from 'clsx';

import SpeakerIcon from '../content/icons/speaker.svg';
import SpeakerPlayingIcon from '../content/icons/speaker-playing.svg';

import {
  playButton,
  playButton__loading
} from './styles/pronunciation.module.scss';

const Pronunciation = ({word}: {word: string}) => {
  const audio = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pronounce = () => {
    setIsPlaying(true);

    if (!audio.current) {
      setIsLoading(true);
      audio.current = new Audio(`/api/1.0/speak?text=${word}`);
      audio.current.addEventListener('canplaythrough', () => {
        setIsLoading(false);
      });
      audio.current.addEventListener('ended', () => {
        setIsLoading(false);
        setIsPlaying(false);
      });
    }

    audio.current.play().catch(() => {
      setIsPlaying(false);
      setIsLoading(false);
    });
  };

  return (
    <button
      type="button"
      className={playButton}
      disabled={isPlaying}
      onClick={pronounce}
    >
      {isPlaying ? (
        <SpeakerPlayingIcon
          className={clsx({[playButton__loading]: isLoading})}
          width="32"
          height="32"
        />
      ) : (
        <SpeakerIcon width="32" height="32"/>
      )}
    </button>
  );
};

export default Pronunciation;
