import ky from "ky";

const api = () =>
  ky.create({
    prefixUrl: "/api/1.0/",
    retry: {
      limit: 5,
    },
  });

export default api;
