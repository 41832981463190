import React, {useState} from 'react';
import {printComponent} from 'react-print-tool/lib/printComponent';
import {AppContextProvider} from '../components/app-context';

import ArcText from '../components/arc-text';

const usePrintArcText = () => {
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const doPrint = (text?: string) => {
    setIsPrintLoading(true);
    printComponent(
      <AppContextProvider>
        <style type="text/css">{'@media print{@page {margin: 0.5in}}'}</style>
        {text
          ?.split('\n')
          .filter((paragraph: string) => paragraph !== '')
          .map((paragraph: string, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${paragraph}-${i}`} style={{margin: '1em auto'}}>
              <ArcText
                isPrintable
                width={650} // TODO: This is ~8.5in. How to make this dynamic?
              >
                {paragraph}
              </ArcText>
            </div>
          ))}
      </AppContextProvider>
    ).then(
      () => {
        setIsPrintLoading(false);
      },
      error => {
        console.error(error);
        setIsPrintLoading(false);
      }
    );
  };

  return {doPrint, isPrintLoading};
};

export default usePrintArcText;
