import React, {useContext} from 'react';

import {DEFAULT_OPTIONS} from '../services/options';
import {AppContext} from './app-context';
import ArcText from './arc-text';

import {syllables as syllables_style} from './styles/syllables.module.scss';

type Props = {
  syllables: string[];
};

const Syllables = ({syllables}: Props) => {
  const {userOptions} = useContext(AppContext);

  const fontSize = userOptions?.fontSize ?? DEFAULT_OPTIONS.fontSize;

  return (
    <ArcText
      isCondensed
      className={syllables_style}
      options={{fontSize: Math.min(fontSize * 2, 50)}}
      width={300}
    >
      {syllables?.join('\n')}
    </ArcText>
  );
};

export default Syllables;
