import React from 'react';

import ArcText from './arc-text';

type Props = {
  synonyms?: string[];
};

const Syllables = ({synonyms}: Props) =>
  synonyms ? <ArcText width={300}>{synonyms.join(', ')}</ArcText> : null;

export default Syllables;
